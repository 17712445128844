import React from 'react'
import { graphql } from 'gatsby'
// We're using Gutenberg so we need the block styles
// these are copied into this project due to a conflict in the postCSS
// version used by the Gatsby and @wordpress packages that causes build
// failures.
// @todo update this once @wordpress upgrades their postcss version
import '../css/@wordpress/block-library/build-style/style.css'
import '../css/@wordpress/block-library/build-style/theme.css'
import Layout from '../components/layout'
import Seo from '../components/seo'

const StatementTemplate = ({ data: { statement } }) => {
  return (
    <Layout className="statement">
      <Seo title={`${statement.title}`} noindex />
      <div className="container-narrower py-3 py-lg-4 py-xl-5">
        <header>
          <h1 className="m-0 p-0">{statement.title}</h1>
        </header>
        <main className="pt-1" dangerouslySetInnerHTML={{ __html: statement.content }} />
      </div>
    </Layout>
  )
}

export default StatementTemplate

export const pageQuery = graphql`
query StatementById(
    $id: String!
  ) {
    statement: wpStatement(id: { eq: $id }) {
      content
      title
    }
  }
`
